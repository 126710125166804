<template>
    <div id="empreendimentos" class="cols">
        <div :class="'col-1-'+ (concluidos?'5': '5' )" v-for="empreendimento in empreendimentos" :key="empreendimento.id">
            <div class="empreendimento" :style="'background-image: url('+$sdk.prefix_images + empreendimento.Thumb+');'" @click="empreendimento_click(empreendimento.LinkAmigavel)">
                
                <div class="logo-bg"></div>
                <div v-if="empreendimento.ThumbLogo != null" class="logo" :style="'background-image: url('+$sdk.prefix_images + empreendimento.ThumbLogo+');'"></div>
                <div v-if="empreendimento.ThumbLogo == null" class="logo">{{empreendimento.Nome}}</div>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'empreendimentos',
        'concluidos'
    ],

    methods: {

        empreendimento_click: function(id){
            this.$router.push('empreendimento/' + id);
        },

    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    #empreendimentos{
        width: 100%;
        flex-wrap: wrap;

        & > div{

            &:nth-child(n+6){

                .empreendimento{
                    margin-top: 10px;
                }
            }


        }
        .empreendimento{
            margin: 0 5px;
            height: 340px;
            background-color: #e4e4e4;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            cursor: pointer;

            transition: all 0.3s;

            


            .logo{
                position: absolute;
                bottom: 0;
                margin: auto;
                display: block;
                width: 100%;
                line-height: 100px;
                height: 100px;
                z-index: 99;

                background-repeat: no-repeat;
                background-position: center;

                transition: all 0.3s;

                color: @cor-branco;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                
            }

            .logo-bg{
                content: "";
                position: absolute;
                width: 100%;
                height: 100px;
                background-color: #000;
                opacity: 0.5;
                z-index: 98;
                bottom: 0;


                transition: all 0.3s;
            }

            &:hover, &focus{

                background-size: 105%;
                .logo,
                .logo-bg{
                    height: 120px;
                    line-height: 120px;
                    
                }

                .logo-bg{
                    opacity: 0.8;
                }
            }

        }
    }

</style>