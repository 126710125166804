<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
    name: 'App',

     metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Silva Packer',
      // all titles will be injected into this template
      titleTemplate: '%s | Silva Packer'
    },

    watch: {

        $route(to, from){

            var $this = this;

            console.log('[to]', to);
            console.log('[from]', from);
            
            //this.gaTrack(to.path);

            setTimeout(function(){

                var params = {
                    page_title: document.title,
                    page_path: to.path
                };

                $this.$gtag.event('page_view', params)

                console.log('[gtag - pageview]', params);

            }, 5000)
            

            
   

        }
    },

/*
    methods: {
        gaTrack(page){
            alert('Google - ' + page);
            gtag('event', 'page_view', {
                page_location: window.location.href,
                page_path: to.path,
                page_title: 'XXX'
            });
        }
    }
*/
  
    
}
</script>
<style lang="less">
  @import "@/assets/less/vars.less";
  @import "@/assets/less/style.less";

</style>