<template>
    <div>
        <s-cabecalho />

        <section id="empreendimentosobre" :style="'background-image: url(' + texto_bg + ');'">
            
            <div class="container pad-t80">
                <div class="cols">
                    <div class="col-6-12 col-off-5-12">
                        <img v-if="logo != null" :src="$sdk.prefix_images + logo" id="logo" />
                        <h1 v-if="logo == null" class="semimagem">{{nome}}</h1>
                    </div>
                </div>
            </div>

            <div class="container pad-b50">
                <div class="cols">
                    <div class="col-6-12 col-off-5-12">
                        <p v-html="texto" :class="texto_cor+ ' ' + (logo == null?'semimagem':null)"></p>
                    </div>
                </div>
            </div>

            <div class="container" v-if="videoYoutube">
                <div class="cols">
                    <div class="col-6-12 col-off-6-12">
                        <iframe width="100%" height="315" :src="'https://www.youtube.com/embed/' + videoYoutube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            
        </section>

        <CoolLightBox 
            :items="comodos" 
            :index="comodo"
            @close="comodo = null">
        </CoolLightBox>

        <section id="comodos" :style="'background-image: url(' + $sdk.prefix_images + fotos_bg + ');'" v-if="fotos_bg != null">
            <div class="saibamais" @click="abrir_comodos">Veja Mais</div>
        </section>

        <section id="galeria"  v-if="fotos_bg == null && comodos.length > 0">
            
            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <h1>Fotos</h1>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        
                        <div class="fotos">
                            <div class="foto" @click="comodo = index" v-for="(foto, index) in comodos" :key="index" :style="'background-image: url('+foto.src+');'">
                                <div class="tituloBG"></div>
                                <div class="titulo">{{foto.title}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section id="icones">

            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <h1>O empreendimento</h1>
                    </div>
                </div>
                
            </div>

            <div class="container" v-if="iconesEmpreendimento">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <img :src="$sdk.prefix_images + iconesEmpreendimento" id="icones" />
                    </div>
                
                </div>
            </div>

            
        </section>

        <section id="plantas" v-if="plantas.length > 0">
            <div class="container">
                <div class="col-8-10 col-off-1-10">
                    <h1>Plantas</h1>
                </div>
            </div>
            <div class="container">
                <div class="col-8-10 col-off-1-10">
                    
                    <c-galeria :galeria="plantas" />

                </div>
            </div>

            
        </section>

        <section id="apartamentos">
            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <h1>Apartamento</h1>
                    </div>
                </div>
                
            </div>

            <div class="container" v-if="iconesApto != null">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <img :src="$sdk.prefix_images + iconesApto" id="icones" />
                    </div>
                
                </div>
            </div>
        </section>

        <section id="andamento" v-if="andamento != null">
            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <h1>Andamento da Obra</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <div id="barras">
                            <img :src="$sdk.prefix_images + andamento" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="localizacao">

            <div class="container">
                <div class="cols">
                    <div class="col-8-10 col-off-1-10">
                        <h1>Localização</h1>
                    </div>
                </div>
            </div>

             <div class="container">
                <div class="cols">
                    <div class="col-3-10 col-off-1-10">
                        <p v-html="LocalizacaoTexto1">
                        </p>
                    </div>
                    <div class="col-3-10">
                        <p v-html="LocalizacaoTexto1"></p>
                    </div>
                    <div class="col-2-10">
                        <p v-html="LocalizacaoTexto1"></p>
                    </div>
                </div>
            </div>

            <div v-html="mapa_iframe"></div>

        </section>

        <s-rodape />
    </div>

    
</template>

<script>

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {

    metaInfo() {
        return {
            title: `${this.nome} - Empreendimento`,
        }
    },
    
    components: {
        CoolLightBox,
    },

    data: function () {
        return {
            nome: null,
            logo: null,
            texto: null,
            texto_cor: null,
            texto_bg: null,
            texto_selo: null,
            videoYoutube: null,
            iconesEmpreendimento: null,
            iconesApto: null,
            andamento: null,
            LocalizacaoTexto1: null,
            LocalizacaoTexto2: null,
            LocalizacaoTexto3: null,
            mapa_iframe: null,
            fotos_bg: null,
            comodos: [],
            comodo: null,
            plantas: []
            
        }
    },

    methods: {
        abrir_comodos: function(){
            this.comodo = 0;
        }
    },

    mounted: function(){

        var $this = this;
        
        this.$sdk.get('empreendimentos?slug=' + this.$route.params.id, function(data){
            console.log('API - Empreendimento', data);

            if(data.length == 0){
                $this.$router.push({ name: '404'});   
                return;
            }

            $this.nome = data[0].Nome;
            $this.logo = data[0].Logo;
            $this.texto = data[0].TextoBR;
            $this.videoYoutube = data[0].VideoYoutubeCodigo;
            $this.iconesEmpreendimento = data[0].IconesEmpreendimento;
            $this.iconesApto = data[0].IconesApto;
            $this.andamento = data[0].Obra;
            $this.LocalizacaoTexto1 = data[0].LocalizacaoTexto1;
            $this.LocalizacaoTexto2 = data[0].LocalizacaoTexto2;
            $this.LocalizacaoTexto3 = data[0].LocalizacaoTexto3;
            $this.mapa_iframe = data[0].MapaIframe;

            //plantas
            data[0].plantas.forEach(planta => {
                $this.plantas.push({
                    nome: planta.Comodo,
                    imagem: $this.$sdk.prefix_images + planta.Imagem
                })
            });

            //fotos (cômodos)
            $this.comodos = []; //zerando
            data[0].fotos.forEach(foto => {
                $this.comodos.push({
                    title: foto.Comodo,
                    src: $this.$sdk.prefix_images + foto.Imagem
                })
            });

            //cor do texto
            if(data[0].TextoCor == 'P')
                $this.texto_cor = 'preto';
            else
                $this.texto_cor = 'branco';

            //background das fotos
            $this.fotos_bg = data[0].BackgroundFotos;

            //background do texto
            $this.texto_bg = $this.$sdk.prefix_images + data[0].BackgroundEmpreendimento;

            //selo do texto (foi desativado o selo)
            //$this.texto_selo = $this.$sdk.prefix_images + data[0].SeloEmpreendimento;

        })

    },


}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    section#empreendimentosobre{
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: auto;

        &:not(.alturalivre){
            min-height: 1073px;
        }

        #logo{
            margin-left: 120px;
            margin-bottom: 45px;

            &.semimagem{
                padding-left: 0;
            }
        }

        p{
            color: #999;
            font-size: 14px;
            color: @cor-cinza-claro;
            line-height: 20px;
            padding-left: 120px;
            display: inline-block;
            
            background-position: left center;
            background-repeat: no-repeat;

            &.semimagem{
                padding-left: 0;
            }

            &.preto{
                color: @cor-preto;
            }
            &.branco{
                color: @cor-branco;
            }
        }

        iframe{
            margin: 0;
            width: 601px;
            height: 338px;
        }

    }

    section#comodos{
        background-color: #E4e4e4;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        height: 1112px;


        .saibamais{
            position: absolute;
            display: block;
            z-index: 999;
            background-color: #ffffff;
            text-transform: uppercase;
            padding: 3px 0;
            font-size: 29px;
            letter-spacing: 1px;
            margin-left: -111px;
            bottom: 556px;
            left: 50%;
            width: 223px;
            text-align: center;
            color: #37312a;
            text-decoration: none;
            opacity: 0.6;
            transition: all 0.5s;
            cursor: pointer;
            font-weight: 700;

            &:hover, &:focus{
                opacity: 1;
            }
        }

    }


    section#icones{
        h1{
            color: @cor-preto;
            margin-top: 70px;
           // text-align: center;
        }

        #icones{
            margin: 70px 0;
            display: block;
        }
    }

    section#plantas{
        background-color: @cor-cinza-claro2;

        h1{
            color: @cor-preto;

            &:after, &:before{
                display: none;
            }
        }


    }

    section#apartamentos{

        background-color: @cor-cinza-claro2;

        h1{
            color: @cor-preto;

            &:after, &:before{
                display: none;
            }
        }

        #icones{
            margin: 20px 0;
            display: block;
            margin-bottom: 45px;
        }
    }

    section#andamento{
         h1{
            color: @cor-vermelho;
            margin-bottom: 25px;

            &:after, &:before{
                display: none;
            }
        }

        #barras{
            width: 100%;
            background-color:  @cor-cinza-claro2;
            padding: 45px 0;
            margin-bottom: 45px;

            img{
                display: block;
                margin: auto;
                max-width: 90%;
            }
        }
    }

    section#localizacao{
        h1{
            color: @cor-vermelho;
            margin-bottom: 0;

            &:after, &:before{
                display: none;
            }
        }
        p{
            font-size: 12px;
            margin-bottom: 45px;
        }

        iframe{
            width: 100%;
            height: 460px;
        }

    }

    section#galeria{

        .fotos{

            display: flex;
            flex-wrap: wrap;

            .foto{

                width: (100%/5);

                background-size: 190%;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                cursor: pointer;
                overflow: hidden;

                transition: 0.3s;

                &:after{
                    padding-bottom: 100%;
                    content: '';
                    display: block;
                }   
                .tituloBG{
                    background-color: @cor-branco;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 0;

                    transition: 0.2s;

                }

                .titulo{
                    color: @cor-preto;
                    position: absolute;
                    left: 0;
                    top: -100%;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 600;
                    transition: 0.4s;
                }

                

                &:hover{
                    background-size: 210%;

                    .tituloBG{
                        opacity: 0.65;
                    }

                    .titulo{
                        top: 0;
                    }
                }

            }
        }

    }
    

</style>