<template>
    <section id="rodape">

        <div id="social">

            <div class="container">
            
                <ul id="social">
                    <li>
                        <a :href="social_facebook" target="_blank">
                            <img src="/assets/images/icon-facebook.svg">
                        </a>
                    </li>
                    <li>
                        <a :href="social_instagram" target="_blank">
                            <img src="/assets/images/icon-instagram.svg">
                        </a>
                    </li>
                    <li>
                        <a :href="social_youtube" target="_blank">
                            <img src="/assets/images/icon-youtube.svg">
                        </a>
                    </li>
                
                </ul>

            </div>

        </div>

        <div id="menu">

            <div class="container">

                <div class="col-2-10">

                    <img src="/assets/images/logo-rodape.png" >

                    <br>

                    <h3>Escritório Central</h3>
                    <p v-html="endereco"></p>

                    <p><strong>CENTRAL DE VENDAS<br>
                        {{central_de_vendas}}</strong>
                    </p>

                </div>

                <div class="col-2-10 menus">
                    <h3>Sobre a Silva Packer</h3>
                    <ul>
                        <li>
                            <router-link :to="{name: 'institucional'}"  @click.native="topo_missao">Quem Somos</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'institucional'}"  @click.native="topo_missao">Missão</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'institucional'}"  @click.native="topo_missao">Visão</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'institucional'}"  @click.native="topo_missao">Valores</router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-2-10 menus">
                    <h3>Empreendimentos</h3>
                    <ul>
                        <li v-for="(emp, index) in empreendimentos" :key="index">
                            <router-link :to="{ name: 'empreendimento', params: { id: emp.LinkAmigavel }}"  @click.native="topo">{{emp.Nome}}</router-link>
                        </li>
                       
                    </ul>
                </div>

                <div class="col-2-10 menus">
                    <h3>Blog</h3>
                    <ul>
                        <li v-for="(cat, index) in categorias" :key="index">
                            <router-link :to="{ name: 'blog-categoria', params: { id: cat.ID, nome: cat.Nome }}"  @click.native="topo">{{cat.Nome}}</router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-2-10 menus">
                    <h3>Fale Conosco</h3>
                    <ul>
                        <li>
                            <router-link :to="{name: 'contato'}" @click.native="topo">Fale conosco</router-link>
                        </li>
                        <!--
                        <li>
                            <a href="#">Quero Comprar</a>
                        </li>
                        <li>
                            <a href="#">Sou Corretor</a>
                        </li>
                        <li>
                            <a href="#">Faça Parte do Nosso Time</a>
                        </li>
                        <li>
                            <a href="#">Seja Fornecedor</a>
                        </li>
                        -->
                    </ul>
                </div>

                
            </div>

            <div class="container">
                <div class="copy">
                    © Copyright 2021 - Todos os direitos reservados | Desenvolvido por <a href="http://www.agenciatempobrasil.com.br" target="_blank">Tempo Brasil</a>.
                </div>
            </div>

        </div>

        

    </section>
</template>

<script>
export default {

    data: function(){
        return {
            endereco: null,
            social_instagram: null,
            social_facebook: null,
            social_youtube: null,
            central_de_vendas: null,
            empreendimentos: [],
            categorias: []
        }
    },

    methods: {

        topo: function(){

            setTimeout(function(){

                var element = document.querySelector('div#miolo');
                var top = element.offsetTop;

                window.scrollTo({
                    top: top,
                    left: 0,
                    behavior: 'smooth'
                });

            }, 400)
            
        },


        topo_missao: function(){

            setTimeout(function(){

                var element = document.querySelector('section#politica-visao');
                var top = element.offsetTop;

                window.scrollTo({
                    top: top,
                    left: 0,
                    behavior: 'smooth'
                });

            }, 400)
            
        },

    },

    mounted: function(){

        var $this = this;
        
        this.$sdk.parametro('ENDERECO', function(result){
            $this.endereco = result.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>'   + '$2');
        });

        
        this.$sdk.parametro('SOCIAL_YOUTUBE', function(result){
            $this.social_youtube = result;
        });

        this.$sdk.parametro('SOCIAL_INSTA', function(result){
            $this.social_instagram = result;
        });

        this.$sdk.parametro('SOCIAL_FACEBOOK', function(result){
            $this.social_facebook = result;
        });

        this.$sdk.parametro('CVENDAS', function(result){
            $this.central_de_vendas = result;
        });

        this.$sdk.get('empreendimentos?naoconcluidos', function(result){
            $this.empreendimentos = result;
        })       
        
        this.$sdk.get('posts_categorias', function(result){
            $this.categorias = result;
        })

    }

}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    section#rodape{

        #social{
        
            background-color: @cor-vermelho;

            display: flex;
            justify-content: center;
            align-content: center;

            

            ul#social{

                list-style: none;
                padding: 18px 0;
                margin: auto;

                display: flex;

                li{

                    flex: 1;
                    padding: 0 73px;
                    margin: 0;

                    border-left: 1px solid #d18091;

                    &:first-child{
                        border: 0;
                    }

                    a{

                        img{
                            width: 25px;
                            height: 25px;

                            transition: all 0.15s;
                        }

                        &:hover, &:focus{
                            img{
                                position: relative;
                                transform: scale(1.1);
                            }
                        }

                    }

                }

            }

        }

        #menu{
            background-color: @cor-cinza-claro2;
            padding: 45px 0;

            h3{
                color: @cor-vermelho;
                text-transform: uppercase;
                font-size: 14px;
                padding-top: 15px;
                
                
            }

            .menus{
                h3{
                    padding-top: 60px;
                }
            }

            p{
                font-size: 12px;
            }

            ul{
                list-style: none;
                margin: 0;
                padding: 0;

                li{
                    margin: 0;
                    padding: 0;

            

                    a{
                        text-decoration: none;
                        color: @cor-preto;
                        font-size: 14px;

                        &:focus, &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }

            .copy{
                text-align: center;
                font-size: 12px;
                padding: 45px 0;
                display: block;
                width: 100%;
                color: @cor-cinza-escuro;

                a{
                    font-weight: 500;
                    color: @cor-preto;
                    text-decoration: none;

                    &:hover, &:focus{
                        color: @cor-vermelho;
                    }
                }
            }
        }
        

    }

</style>