<template>
    
    <div>
        <s-cabecalho />

        <section id="blog-detalhe">
            <div class="container pad-t60 pad-b60">
                
                <div class="cols">

                    <div class="col-2-3">
                        
                        <div class="artigo">
 
                            <img :src="$sdk.prefix_images + ImagemCapa" alt="" class="thumb">  

                            <h1>{{Titulo}}</h1>

                            <div v-html="Texto"></div>

                        </div>

                        <footer>
                            <div class="cols">
                                <div class="col-3-4">
                                    <div class="tags">
                                        <router-link :to="'/blog/tag/'+tag"  v-for="(tag, index) in tags_array" :key="index">{{tag}}</router-link>
                                    </div>
                                </div>
                                <div class="col-1-4 ">
                                    <!--
                                    <div class="compartilhe">
                                        <span class="titulo">Compartilhe</span>
                                        
                                        <a href="#">
                                            <i class="icon-facebook"></i>
                                        </a>
                                        <a href="#">
                                            <i class="icon-twitter"></i>
                                        </a>
                                        <a href="#">
                                            <i class="icon-whatsapp"></i>
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </footer>
                        
                    </div>

                    <div class="col-1-3">
                        
                        <c-sidebar />
                        
                    </div>


                </div>
                
            </div>
        </section>

        <!-- @tihhgoncalves desativei porque não tem lista de comentários -->
        <!--
        <section id="comentarios">
            <div class="container">

                <div class="col-4-9  col-off-1-9">
                    <h3>Comentários</h3>
                    <form action="#" method="POST" class="rodape">
                        <input type="text" placeholder="Seu Nome" required />
                        <input type="email" placeholder="Seu Melhor E-mail" required />
                        <textarea name="msg" placeholder="Escreva sua mensagem..."></textarea>
                        <button>Enviar</button>
                    </form>
                </div>

            </div>
        </section>
        -->

        <s-rodape />
    </div>


</template>

<script>
export default {

    metaInfo() {
        return {
            title: `${this.Titulo} - Artigo`,
        }
    },

    data: function(){
        return {
            ImagemCapa: null,
            Titulo: null,
            Texto: null,
            tags_array: []
        }
    },

    beforeRouteUpdate(to, from, next) {

        next();
        this.carrega_post(to.params.slug);
    },

    mounted: function(){

        this.carrega_post(this.$route.params.slug);

    },

    methods: {

        carrega_post(slug){

            this.topo();

            var $this = this;

            this.$sdk.get('posts?slug=' + slug, function(data){

                console.log('[artigo]', data);

                if(data.length == 0){
                    $this.$router.push({ name: '404'});   
                    return;
                }

                console.log('API - Carregando o post: ' +  + $this.$route.params.slug);

                $this.ImagemCapa = data[0].ImagemCapa;
                $this.Titulo = data[0].Titulo;
                $this.Texto = data[0].Texto;
                $this.tags_array = data[0].tags_array;


            })

        },

        topo: function(){
            
            var element = document.querySelector('div#miolo');
            var top = element.offsetTop;

            window.scrollTo({
                top: top,
                left: 0,
                behavior: 'smooth'
            });
            
        },

    }
}
</script>
<style lang="less">
    @import "@/assets/less/vars.less";

    section#blog-detalhe{
        background-color: @cor-cinza-escuro;
        
        .artigo{

            padding-right: 25px;
            border-right: 2px solid@cor-cinza-claro ;

            img{
                max-width: 100%;
                height: auto;
            }

            h1{
                letter-spacing: 0;
                
                line-height: 35px;
                font-size: 26px;
                text-transform: none;

                color: @cor-cinza-claro;

                &::before{
                    display: none;
                }
            
            }

            p{
                color: @cor-branco;
                line-height: 28px;
                font-size: 14px;
                display: inline-block;
            }
        }

        footer{
            margin-top: 100px;
            padding: 25px;
            border-top: 1px solid @cor-cinza-claro;

            .tags{
                
                a{
                    color: @cor-vermelho;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 12px;
                    padding: 15px;
                    font-weight: 600;
                    display: block;
                    float: left;
                    padding-bottom: 5px;
                    transition: all 0.3s;
                    &:hover, &:focus{
                        color: @cor-branco;
                    }
                }
            }

            .compartilhe{

                .titulo{
                    color: @cor-vermelho;
                    font-size: 11px;
                    padding-top: 15px;
                    display: block;
                    text-align: right;
                }

                a{
                    padding: 5px;
                    padding-top: 10px;
                    display: block;
                    float: right;
                    text-decoration: none;

                    
                    i{
                        color: @cor-vermelho;
                        transition: all 0.3s;
                    }

                    &:hover, &focus{
                        i{
                            color: @cor-branco;
                        }
                    }
                }
            }
            
        }
    }

    section#comentarios{
        padding: 25px;
    }

    

</style>