import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

//Importa rotas
import routes from "./routes"
const router = new Router({
    mode: 'history',
    base: '/',
    routes
})

export default router