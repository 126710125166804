<template>
    <section id="cabecalho">
       
    <!-- Barra do Topo -->
       <header class="cols">
           <div class="container">
                <div class="col-6-12">
                    <span>{{frase}}</span>
                </div>
                <div class="col-off-4-12 col-2-12">
                    <ul id="social">
                        <li>
                            <a :href="social_facebook" target="_blank">
                                <img src="/assets/images/icon-facebook.svg">
                            </a>
                        </li>
                        <li>
                            <a :href="social_instagram" target="_blank">
                                <img src="/assets/images/icon-instagram.svg">
                            </a>
                        </li>
                        <li>
                            <a :href="social_youtube" target="_blank">
                                <img src="/assets/images/icon-youtube.svg">
                            </a>
                        </li>
                        <li v-if="$route.name == 'blog' | $route.name == 'artigo' | $route.name == 'blog-categoria' | $route.name == 'blog-datas' | $route.name == 'blog-tags' | $route.name == 'blog-pesquisa'">
                            <div class="pixel-branco"></div>
                        </li>
                        <li v-if="$route.name == 'blog' | $route.name == 'artigo' | $route.name == 'blog-categoria' | $route.name == 'blog-datas' | $route.name == 'blog-tags' | $route.name == 'blog-pesquisa'">
                            <a  @click="abre_pesquisa">
                                <img src="/assets/images/icon-lupa.svg" id="s">
                            </a>
                        </li>
                    </ul>
                </div>
                
            </div>
       </header>

       <article>

           <div id="topo">
               
               <div class="container">
                   
                   <div class="col-3-12">
                        <router-link to="/">
                            <img src="/assets/images/logo.png" alt="">

                        </router-link>
                   </div>

                   <div class="col-9-12">
                        <div id="menu">
                           <router-link to="/">Home</router-link>
                            <router-link to="/institucional">Institucional</router-link>
                            <router-link to="/empreendimentos">Empreendimentos</router-link>
                            <router-link to="/blog">Blog</router-link>
                            <router-link to="/contato">Contato</router-link>
                        </div>
                    </div>
                   
                   
               </div>
               
           </div>
           
           <c-banners />
           
       </article>

        <!-- marcação de onde começa o conteúdo -->
       <div id="miolo"></div>

    </section>
</template>

<script>
import Swal from 'sweetalert2'
export default {

    data: function(){
        return {
            social_instagram: null,
            social_facebook: null,
            social_youtube: null,
            frase: null

        }
    },
    methods: {

       
        abre_pesquisa: function(){

            var $this = this;

            Swal.fire({
                title: 'Pesquise no Blog',
                input: 'text',
                inputLabel: 'Digite abaixo uma palavra que contenha no post que você está procurando.',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Você não pode deixar o campo em branco.'
                    }
                },
                confirmButtonText: 'Enviar',
                    showCancelButton: true,

            }).then((result) => {

                console.log('[PESQUISA]', result);

                if(result.isConfirmed)
                    $this.$router.push({ name: 'blog-pesquisa', params: { pesquisa: result.value } });
            })

        },

        emp_ancora: function(){
            
            if(this.$route.name != 'home')
                this.$router.push({ name: 'home'});

            setTimeout(function(){
                var element = document.querySelector('section#emp-reg');
                var top = element.offsetTop;

                window.scrollTo({
                    top: top,
                    left: 0,
                    behavior: 'smooth'
                });

            }, 500)
             

        }

    },

    mounted: function(){

        var $this = this;

        this.$sdk.parametro('SOCIAL_YOUTUBE', function(result){
            $this.social_youtube = result;
        });

        this.$sdk.parametro('SOCIAL_INSTA', function(result){
            $this.social_instagram = result;
        });

        this.$sdk.parametro('SOCIAL_FACEBOOK', function(result){
            $this.social_facebook = result;
        });

        this.$sdk.parametro('FRASE', function(result){
            $this.frase = result;
        });

    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    section#cabecalho{
        color: @cor-branco;
        
        
        header{
            background-color: @cor-vermelho;
            
            span{
                line-height: 32px;
                font-size: 11px;
                font-weight: 600;
            }
        }

        ul#social{
            list-style: none;
            display: flex;
            margin: auto;
            padding: auto;

            li{

                &:first-child{
                    margin-left: auto;
                }

                flex: 1;
                height: 32px;
                align-items: center;
                justify-content: center;
                display: flex;

                img{
                    width: auto;
                    height: 19px;
                }

            }

            #s{
                cursor: pointer;
            }
        }

        .pixel-branco{
            height: 19px;
            width: 1px;
            background-color: @cor-branco;
        }


        #topo{
            position: absolute;
            z-index: 9999;
            width: 100%;

        }


        #menu{
            

            display: flex;
            padding-top: 40px;

            a{

                &:first-child{
                    margin-left: auto;
                }

                text-decoration: none;
                text-transform: uppercase;
                font-weight: 600;
                padding: 0 15px;
                color: @cor-branco;
                font-size: 14px;
                position: relative;

                text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.58);

                &.ativo{
                    color: @cor-vermelho;
                }

                &:not(:first-child)::before{
                    content: "";
                    background-color: @cor-branco;
                    width: 2px;
                    height: 19px;
                    margin-left: -15px;
                    position: absolute;
                    opacity: 0.3;
                }


                &:after{
                    content: "";
                    background-color: @cor-branco;
                    position: absolute;
                    height: 2px;
                    width: 0;
                    left: 10%;
                    bottom: -10px;
                    transition: 0.2s;
                    display: flex;
                        
                }

                &.ativo:after{
                    background-color: @cor-vermelho;
                }


                &:hover, &:focus{

                    &:after{
                        width: 80%;
                    }

                }
            }

        }


    }

</style>