import Vue          from 'vue'
import App          from './App.vue'
import router       from "./router"

require('dotenv').config()
Vue.config.productionTip = false

import VueMeta from 'vue-meta'
Vue.use(VueMeta)


import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-42DTR0MC5Q" },
}, router);


/*
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
*/

require("./sections")
require("./components")

import sdk from "./sdk"
Vue.use(sdk);

import helpers from "./helpers"
Vue.use(helpers);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
