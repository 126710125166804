<template>
    <div>
        <s-cabecalho />

        <section id="empreendimentos" v-show="empreendimentos.length > 0">
            <div class="container">
                <h1>Empreendimentos</h1>
            </div>

            <div class="container">
                <c-empreendimentos :empreendimentos="empreendimentos" />
            </div>
        </section>

        
        <section id="empreendimentos" v-show="empreendimentos_prelancamentos.length > 0">
            <div class="container">
                <h1>Pré-lançamentos</h1>
            </div>

            <div class="container">
                <c-empreendimentos :empreendimentos="empreendimentos_prelancamentos" />
            </div>
        </section>


        
        <section id="empreendimentos" class="pad-t50 pad-b50" v-show="empreendimentos_concluidos.length > 0">
            <div class="container">
                <h1>Obras Concluídas</h1>
            </div>

            <div class="container">
                <c-empreendimentos :empreendimentos="empreendimentos_concluidos" :concluidos="true" />
            </div>
        </section>

        <s-rodape />
    </div>

</template>


<script>
export default {
    
    metaInfo() {
        return {
            title: 'Empreendimentos',
            meta: [
               { 
                   name: 'description', 
                   content: 'Os empreendimentos da Silva Packer estão localizados em Balnéario Camboriú, visando o conforto, satisfação e segurança de seus clientes.' 
                },
                { 
                    name: 'keywords', 
                    content: 'Empreendimentos, Balneário Camboriú, Balneario Camburiu, silva packer, silvia packer, silva paker, santa catarina, imóveis, apartamentos, alto padrão, imóveis alto padrão, litoral catarinense, sul, investimento, investimento em imóveis, imobiliária, solar di siena, solar di capri, 135 jardins, tesla' 
                }
            ]
        }
    },

    data: function () {
        return {
            empreendimentos: [],
            empreendimentos_concluidos: [],
            empreendimentos_prelancamentos: [],
        }
    },

    methods: {
        
        load_empreendimentos: function(){

            var $this = this;

            $this.empreentimentos = [];
            this.$sdk.get('empreendimentos?naoconcluidos', function(data){
                $this.empreendimentos = data;
            })


            $this.empreendimentos_concluidos = [];
            this.$sdk.get('empreendimentos?concluidos', function(data){
                $this.empreendimentos_concluidos = data;
            })

            $this.empreendimentos_prelancamentos = [];
            this.$sdk.get('empreendimentos?prelancamentos', function(data){
                $this.empreendimentos_prelancamentos = data;
            })

        }
    },

    mounted: function(){
        this.load_empreendimentos();
    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    h1{
        color: @cor-preto;
    }

</style>