import Home             from "./pages/Home"
import Institucional    from "./pages/Institucional"
import Blog             from "./pages/Blog"
import BlogDetalhe      from "./pages/BlogDetalhe"
import Contato          from "./pages/Contato"
import Empreendimento   from "./pages/Empreendimento"
import Empreendimentos  from "./pages/Empreendimentos"
import erro404              from "./pages/404"

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'institucional',
        path: '/institucional',
        component: Institucional,
    },
    {
        name: 'contato',
        path: '/contato',
        component: Contato
    },
    {
        name: 'blog',
        path: '/blog',
        component: Blog
    },
    {
        name: 'artigo',
        path: '/blog/:slug',
        component: BlogDetalhe
    },
    {
        name: 'blog-categoria',
        path: '/blog/categoria/:id/:nome',
        component: Blog
    },
    {
        name: 'blog-datas',
        path: '/blog/datas/:ano/:mes',
        component: Blog
    },
    {
        name: 'blog-tags',
        path: '/blog/tag/:tag',
        component: Blog
    },
    {
        name: 'blog-pesquisa',
        path: '/blog/s/:pesquisa',
        component: Blog
    },
    {
        name: 'empreendimento',
        path: '/empreendimento/:id',
        component: Empreendimento
    },
    {
        name: 'empreendimentos',
        path: '/empreendimentos',
        component: Empreendimentos
    },
    {
        name: '404',
        path: '/*',
        component: erro404
    }
  ]

  export default routes