<template>
    <div>
        <div id="banners">
                <div v-for="(banner, index) in banners" :key="index" :id="banner.id" :style="(banner.tipo == 'imagem'?'background-image: url(' + banner.url + ');':null)">

                    <div class="bg-video" v-if="banner.tipo == 'video'">
                        <video  :src="banner.url" loop muted playsinline v-show="!is_iphone"></video>
                        <canvas width="2000" height="753"></canvas>
                    </div>

               </div>
           </div>
           <a id="banners-saibamais">Saiba Mais</a>
           <div id="banner-nav"  v-show="banners.length > 0">
               <ul id="banner-nav-ul">
               </ul>
           </div>

           <i class="seta icon-seta-d" @click="proximo" v-show="banners.length > 0"></i>
           <i class="seta icon-seta-e" @click="anterior" v-show="banners.length > 0"></i>
    </div>
</template>

<script>

export default {

    data: function () {
        return {
            banners: [],

            banner_atual: null,
            banner_keys: [],
            timeouts: [],

            is_iphone: false
        }
    },

    methods: {

        stop: function(){

            var $this = this;

            $this.timeouts.forEach(function(item){
                clearTimeout(item);
                //console.log('[timeouts stop]', $this.timeouts);
            })

            //zera lista
            $this.timeouts = [];
            $this.save_timeouts();

            //dá stop em todos os banners vídeos
            var videos = document.querySelector('#banners video');
            if(videos)
                document.querySelector('#banners video').pause();

        },

        save_timeouts: function(){
            window.localStorage.setItem('timeouts', this.timeouts);

            console.log('[timeouts save]', this.timeouts);
        },
        load_timeouts: function(){
            var reg = window.localStorage.getItem('timeouts');

            if(reg){
                this.timeouts = reg.split(',');
            }
            
            console.log('[timeouts load]', this.timeouts);
        },

        prepara_banners: function(){

            var $this = this;

            // carrega keys dos banners
            $this.banners.forEach(function(item){
                $this.banner_keys.push(item.id);
            })

            // monta navegador dos banners
            $this.banners.forEach(function(item){


                var novo = document.createElement("li");
                novo.id = item.id;
                novo.onclick = function(){

                    //para agendamento atual
                    $this.stop();

                    //gira banners até chegar o que foi clicado
                    while($this.banner_keys[0] != this.id){

                        var proximo_key = $this.banner_keys.shift();
                        $this.banner_keys.push(proximo_key);

                    }

                    //depois de reposicionar os banners, ativa novamente
                    $this.anima_banners();                    

                }
                document.querySelector('#banner-nav ul').appendChild(novo);

            })

            //inicia a animação
            $this.anima_banners();

        },

        anima_banners: function () {

            var $this = this;

            var transicao_tempo = 0.8;

            // vê qual é o próximo banner e tira da fila
            var proximo_key = this.banner_keys.shift();
            var proximo = this.banners.filter(function(value){
                return value.id == proximo_key;
            })
            proximo = proximo[0];

            if(this.banner_atual != null){

                /* ESCONDE BANNER ANTIGO */
                //document.getElementById(this.banner_atual).style.display = 'none';
                document.getElementById(this.banner_atual).style.zIndex  = 998;
                document.getElementById(this.banner_atual).style.transition = transicao_tempo + 's';
                document.getElementById(this.banner_atual).style.opacity = 0;
                //console.log('[banner] ESCONDE ', this.banner_atual);

                //despinta a bolinha do nav
                document.querySelector('#banner-nav ul li#' + this.banner_atual).classList.remove('ativo');
            }


            /* MOSTRA BANNER NOVO */
            //document.getElementById(proximo_key).style.display = 'block';
            document.getElementById(proximo_key).style.zIndex  = 999;
            document.getElementById(proximo_key).style.transition = transicao_tempo + 's';
            document.getElementById(proximo_key).style.opacity = 1;
            //console.log('[banner] MOSTRA ', proximo_key);

            if(proximo.tipo == 'video'){

                var video = document.querySelector('#banners #' + proximo_key + ' video');
                var canvas = document.querySelector('#banners #' + proximo_key + ' canvas');
                var canvas_loop = null;
  

                if($this.is_iphone){


                    canvas_loop = setInterval(function(){
                        $this.paintVideo(video, canvas);
                    }, (1000 / 30) )
                }

                              
                video.load();
                video.play();


                $this.timeouts.push(setTimeout(function(){

                    video.pause();
                    clearInterval(canvas_loop);

                }, (15 * 1000)));

                //salva timeouts no local
                $this.save_timeouts();

                
            }

            //verifica se essa novo banner tem LINK        
            if(proximo.link && proximo.link.length > 0){
                document.querySelector('#banners-saibamais').style.transition = transicao_tempo + 's';
                document.querySelector('#banners-saibamais').style.opacity = 1;
            } else {
               document.querySelector('#banners-saibamais').style.transition = transicao_tempo + 's';
               document.querySelector('#banners-saibamais').style.opacity = 0;
            }
            

            // pinta bolinha no nav
            document.querySelector('#banner-nav ul li#' + proximo_key).classList.add('ativo');

            //seta o link SAIBA MAIS do banner atual
            document.getElementById('banners-saibamais').href = proximo.link;

            //joga banner que era atual pra trás
            setTimeout(function(){
                document.getElementById($this.banner_atual).style.zIndex  = -1;
            }, 1000)

            //atualiza o banner atual
            this.banner_atual = proximo_key;

            //console.log('Banner ' + this.banner_atual, proximo);

            //adiciona o próximo no final da fila
            this.banner_keys.push(proximo_key);

            $this.timeouts.push(setTimeout(function(){

                $this.anima_banners();

            },  (proximo.tempo*1000) + (transicao_tempo *1000) ));

            //salva timeouts no local
            $this.save_timeouts();


        },

        proximo: function(){

            var $this = this;
            
            //para agendamento atual
            $this.stop();

            //seleciona próximo banner
            //console.log('[banner_keys 1]', $this.banner_keys);
            //var proximo_key = $this.banner_keys.shift();
            //console.log('[banner_keys 2]', $this.banner_keys);
            //$this.banner_keys.push(proximo_key);
            console.log('[banner_keys 3]', $this.banner_keys);

            //depois de reposicionar os banners, ativa novamente
            $this.anima_banners();                    
        },

        anterior: function(){
            
            var $this = this;
            
            //para agendamento atual
            $this.stop();

            //seleciona o banner atual e coloca de novo na lista (nesse momento, ele já está no final)
            var ultimo = $this.banner_keys.pop();
            $this.banner_keys.unshift(ultimo);
            
            //seleciona o post anterior e posiciona ele no começo da lista
            ultimo = $this.banner_keys.pop();
            $this.banner_keys.unshift(ultimo);


            //depois de reposicionar os banners, ativa novamente
            $this.anima_banners(); 

        },

        paintVideo: function(video, canvas){
            
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            
        }
    },

    mounted:function(){

        this.load_timeouts();

        this.is_iphone = navigator.userAgent.indexOf('iPhone') >= 0;

        var $this = this;

        //zera lista
        var banners = [];

        var uri = 'banners';

        if($this.$route.name == 'empreendimento'){
            uri += '?empreendimento=' + $this.$route.params.id;
        }

        this.$sdk.get(uri, function(data){

            data.forEach(function(item){

                 var banner = {
                    id: 'banner-' + item.ID,
                    tipo: (item.Tipo == 'I'?'imagem':'video'),
                    link: item.Link,
                    target: item.LinkDestino,
                    tempo: (item.Tipo == 'I'?5:15),
                    url: $this.$sdk.prefix_images + (item.Tipo == 'I'?item.Imagem:item.Video)
                };

                banners.push(banner);

            })

            $this.banners = banners;

            //prepara exibição de banners
            setTimeout(function(){
                $this.prepara_banners();
            }, 100);
            
        });
        

        
    },

    updated: function(){

        var $this = this;

        this.load_timeouts();

        //setTimeout(function(){
            $this.stop();
        //}, 100)
        
    },






}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    #banners{

        @altura: 753px;

        height: @altura;
        position: relative;

        & > div{
            height: @altura;
            width: 100%;

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;

            //background-color: red;
            //display: none;
            opacity: 0;
            z-index: -1;
        }

        &  .bg-video {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1; /*apenas um -1 é necessário quando se trabalha com relative + absolute, sendo pai e filho*/
            width: 100vw;
            height: 100%;
            overflow: hidden; /* evita do video passar a altura desejada do .wrap */
         
            video {
                height: 753px;
                width: 100%;
                object-fit: cover;
                
            }

            canvas {
                height: 753px;
                width: 100%;
                object-fit: cover;
                
            }
        }

    }

    #banners-saibamais{

        @largura: 130px;

        position: absolute;
        display: block;
        z-index: 999;
        background-color: @cor-branco;
        text-transform: uppercase;
        padding: 3px 0;
        font-size: 14px;
        letter-spacing: 1px;
        margin-top: -100px;
        margin-left: -( @largura / 2);
        left: 50%;
        width:  @largura;
        text-align: center;
        color: @cor-preto;
        text-decoration: none;
        opacity: 0.6;
        transition: all 0.5s;

        &:hover, &:focus{
            opacity: 1;
        }
    }

    #banner-nav{
        display: flex;
        position: absolute;
        margin: 0;
        padding: 0;
        margin-top: -60px;
        height: 25px;
        z-index: 999;
        width: 100%;

        align-items: center;
        justify-content: center;

        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                width: 15px;
                height: 15px;
                background-color: @cor-branco;
                float: left;
                padding: 0;
                margin: 0 5px;
                cursor: pointer;
                opacity: 0.6;

                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;

                &.ativo{
                    opacity: 1;
                    //background-color: red;
                }

            }
        }

    }

    .seta{
        color: @cor-branco;
        font-size: 150px;
        position: absolute;
        top: 300px;
        z-index: 9999;
        opacity: 0.6;
        cursor: pointer;
        line-height: 200px;

        transition: 0.3s;

        &.icon-seta-d{
            right: 20px;
        }
        &.icon-seta-e{
            left: 20px;
        }

        &:hover{
            opacity: 1;
            font-size: 160px;
        }
    }
    
</style>