<template>
    <div class="sidebar">

        <div class="item">
            <form @submit="pesquisar">
                <input type="text" name="s" id="s" placeholder="Busca" min="3" v-model="pesquisa" required>
                <input type="image" class="btn" src="/assets/images/icon-lupa.png" />
            </form>
        </div>


        <div class="item">
            <h3 class="cor-cinza">Categorias</h3>
            
            <ul>
                <li v-for="(cat, index) in categorias" :key="index">
                    <router-link :to="{ name: 'blog-categoria', params: { id: cat.ID, nome: cat.Nome }}">{{cat.Nome}}</router-link>
                </li>
               
            </ul>
            
        </div>

        <div class="item">

            <h3>Posts Recentes</h3>

            <div class="post" v-for="(post, index) in ultimos_posts" :key="index" @click="abre_post(post)">
                <div class="thumb" :style="'background-image: url('+ $sdk.prefix_images + post.ImagemCapa +');'"></div>
                <span>{{post.Titulo}}</span>
            </div>

        </div>


        <div class="item">
            <h3>Tags</h3>

            <div class="tags">
                
                <router-link :to="'/blog/tag/'+tag" v-for="(tag, index) in tags" :key="index">{{tag}}</router-link>
                
            </div>
        </div>


        
        <div class="item">
            <h3>Arquivos</h3>

                <ul class="claro">

                    <li v-for="(dat, index) in datas" :key="index">
                        <router-link :to="'/blog/datas/'+dat.ano+'/'+dat.mes">{{dat.legenda + ' de ' + dat.ano}}</router-link>
                    </li>
                
                </ul>
        </div>

    </div>
</template>

<script>
export default {
    data: function(){
        return {
            ultimos_posts: [],
            tags: [],
            categorias: [],
            datas: [],
            pesquisa: null,
        }
    },

    methods: {


        pesquisar: function(e){
            e.preventDefault();
            this.$router.push({ name: 'blog-pesquisa', params: { pesquisa: this.pesquisa } });
        },

        abre_post: function(post){
            this.$router.push({ name: 'artigo', params: { slug: post.LinkAmigavel }});
        },

        load_ultimos_posts: function(){

            var $this = this;

            this.$sdk.get('posts', function(data){

                console.log('API - Carregando últimos posts...');

                //seleciona somente alguns posts
                data = data.splice(0, 5);

                data.forEach( function(post) {

                    post.Texto = post.Texto.replace(/(<([^>]+)>)/gi, ""); //retira tags do texto
                    $this.ultimos_posts.push(post);

                });

            })
        },

        load_categorias: function(){

            var $this = this;

            this.$sdk.get('posts_categorias', function(data){

                console.log('API - Categorias');

                $this.categorias = data;


            })

        },


        load_datas: function(){
            var $this = this;

            this.$sdk.get('posts_datas', function(data){
                console.log('API - Datas');
                $this.datas = data;
            })
        },

        load_tags: function(){

            var $this = this;

            this.$sdk.get('posts_tags', function(data){
                console.log('API - Tags');
                $this.tags = data;
            })

        }

    },

    mounted: function(){

        this.load_ultimos_posts();
        this.load_categorias();
        this.load_datas();
        this.load_tags();


        //atualiza o campo pesquisa de acordo com URL
        this.pesquisa = this.$route.params.pesquisa;
                 

    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";
    
    .sidebar{

        .item{

            padding: 25px;
            position: relative;

            &:first-child{
                padding-top: 0;
            }

            input#s{
                line-height: 40px;
                display: flex;
                width: 100%;
                box-sizing: border-box;

                text-transform: uppercase;
                padding: 0 25px;

            }

            .btn{
                right: 40px;
                bottom: 40px;
                position: absolute;
            }

            h3{
                color: @cor-cinza-claro;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 22px;
                color: @cor-vermelho;

                &.cor-cinza{
                    color: @cor-cinza-claro;
                }
            }

            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                li{
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid @cor-vermelho;
                    a{
                        color: @cor-vermelho;
                        line-height: 40px;
                        text-decoration: none;
                        padding-left: 0;
                        transition: 0.2s;

                        &:hover, &:focus{
                            padding-left: 5px;
                        }
                    }

                    &:after{
                        content: "»";
                        color: @cor-vermelho;
                        right: 30px;
                        position: absolute;
                        margin-top: 10px;
                    }

                    &:last-child{
                        border: 0;
                    }
                }

                &.claro{
                    li{
                        border-color: @cor-cinza-claro;

                        a{
                            color: @cor-cinza-claro;
                        }

                        &:after{
                            color: @cor-cinza-claro;
                        }
                    }
                }
            }

            .post{

                display: flex;
                border-bottom: 1px solid @cor-branco;
                margin-top: 25px;
                cursor: pointer;


                &:last-child{
                    border: 0;
                }

                .thumb,
                span{
                    flex: 1;
                }

                .thumb{
                    width: 100%;
                    margin-right: 25px;
                    margin-bottom: 25px;
                    background-size: auto 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: 0.3s;

                    &:after{
                        padding-bottom: 100%;
                        content: '';
                        display: block;
                    }
                }  
                
                span{
                    color: @cor-branco;
                    line-height: 20px;
                    font-size: 14px;
                }

                &:hover, &:focus{
                    .thumb{
                        background-size: auto 110%;
                    }
                }
            }

            .tags{

                display: block;

                a{
                    margin: 5px;
                    display: inline-flex;
                    color: @cor-branco;
                    font-size: 14px;
                    text-decoration: none;

                    &:hover, &:focus{
                        text-decoration: underline;
                    }
                }

            }

        }

        
    }
</style>