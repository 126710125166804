<template>
    <div>
        <div class="menu">
            
            <span v-for="(grupo, index) in galeria" :key="index" :id="'grupo-'+index"  @click="seleciona">{{grupo.nome}}</span>
            
        </div>

        <div class="wins">
            
            <div class="win" v-for="(imagem, index) in galeria" :key="index" :id="'grupo-'+index" :style="'background-image:url('+imagem.imagem+');'">
            </div>

        </div>

    </div>
</template>

<script>
export default {
    
    props: ['galeria'],

    methods: {

        seleciona: function(elemento){

            //limpa todos os menus
            document.querySelectorAll(".menu span").forEach(function(item){
                item.classList.remove('ativo');
            })

            //esconde todas as sessões
            document.querySelectorAll(".wins .win").forEach(function(item){
                item.style.transition = 0.3+ 's';
                item.style.opacity = 0;
            })

            //marca menu ativo
            elemento.currentTarget.classList.add('ativo');

            //mostra sessão
            document.querySelector(".wins #"+elemento.currentTarget.id).style.opacity = 1;

            //console.log('XXXX', elemento);
            //console.log('AA', ".wins #"+elemento.currentTarget.id);

        }
    },

    mounted: function(){

        setTimeout(function(){
            document.querySelector(".menu span").classList.add('ativo');
            document.querySelector(".wins .win").style.opacity = 1;
        }, 1000)

    }
}
</script>

<style lang="less" scoped>

    @import "@/assets/less/vars.less";

    .menu{
        background-color: @cor-vermelho;
        line-height: 33px;
        display: flex;
        padding: 0 100px;
        justify-content: center;
        margin-bottom: 40px;

        span{
            flex: 1;
            color: @cor-branco;
            text-transform: uppercase;
            padding: 0 15px;
            font-size: 13px;
            text-align: center;
            cursor: pointer;

            &.ativo{
                color: #010101;
                font-weight: 500;
            }
        }
    }

    .wins{
        height: 480px;
        margin-bottom: 40px;
        width: 100%;
        position: relative;

        .win{
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            height: 480px;
            opacity: 0;
            position: absolute;
            width: 100%;
        }
    }
</style>