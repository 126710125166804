<template>
    <div>
        <s-cabecalho />
        
        <section id="quemsomos">
            <div class="container pad-t60 pad-b60">
                <div class="col-1-2">
                    <!--<img src="/assets/images/institucional-foto.jpg" class="institucional-foto" />-->
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/5YL6xfs3gYA?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div>
                <div class="col-1-2">
                    <h1>Quem Somos</h1>

                    <p>A jornada da SILVA PACKER CONSTRUTORA e INCORPORADORA Ltda. teve início em 2001, quando Carlos Humberto Metzner Silva e João Paulo Packer Silva, com a ajuda e dedicação de Gina Carles Packer Silva (em memória), decidiram criar uma empresa que iria além, trilhando um caminho de excelência nos negócios.</p>
                    <p>O pontapé inicial foi dado com o lançamento do Residencial Solar Das Acácias, situado na rua 3618, marcando o primeiro ato do legado Silva Packer na Construção Civil de Balneário Camboriú. Desde então, a construtora e incorporadora tem se dedicado a inovar e elevar os padrões do mercado, sempre com foco no conforto, satisfação e segurança dos seus clientes.</p>
                    <p>Atualmente, nosso escritório está na rua 3420, 135, esquina com Av. Brasil, anexo à obra do 135 Jardins e já edificamos 19 edifícios. Além disso, estamos ansiosos com cinco novos projetos em andamento, que prometem continuar a nossa tradição de construir empreendimentos que transcendem as expectativas.</p>
                    <p>Nossa história é feita de tijolo por tijolo, mas também é moldada por sonhos, paixão e compromisso.</p>
     
                </div>
            </div>
        </section>


        <section id="politica-visao">
            <div class="container">

                <div class="col-7-12 col-off-1-12">

                    <h3>Missão</h3>
                    <p>Desenvolver empreendimentos de alto padrão, alinhando qualidade e seriedade, gerando segurança e retorno para os investimentos de nossos clientes.</p>

                    <h3>Visão</h3>
                    <p>Crescer continuamente no seguimento da construção e incorporação imobiliária de alto padrão, priorizando ética, sofisticação e pontualidade.</p>

                    <h3>Valores</h3>
                    <p>1.º Pertencimento. Somos uma equipe unida, que trabalha com respeito e lealdade, visando o constante crescimento e sucesso da empresa.</p>
                    <p>2.º Compromisso. Trabalho honesto e de excelência, dando o nosso melhor em favor da equipe e da empresa.</p>
                    <p>3.º Efetividade. Agimos com objetividade, buscando sempre o cumprimento dos prazos e da qualidade.</p>

                </div>
                <div class="col-2-12  col-off-1-12">
                    <img src="/assets/images/politica-visao-logo.png" />
                </div>

            </div>
        </section>


        <section id="grupo">

            <div class="container">
                <h1>Grupo Silva Packer</h1>
            </div>

            <div class="container container-empresa">
                <div class="empresa cols">
                    <div class="col-2-10 thumb">
                        <img src="/assets/images/grupo-silvapacker.png" width="150" height="67" />
                    </div>
                    <div class="col-8-10">
                        <p>A história do Grupo Silva Packer está diretamente ligada ao desenvolvimento de Balneário Camboriú. Os sonhos, a dedicação e o trabalho duro da família, auxiliam todo o sucesso e desenvolvimento da região. Carlos Humberto Metzner Silva e João Paulo Packer Silva comandam o grupo econômico com mais de seis empresas voltadas aos setores de construção e incorporação, gerenciamento patrimonial e serviços. A Silva Packer Construtora, por exemplo, está entre as melhores empresas do setor da construção civil no Sul do país, tendo destaque no mercado por seus empreendimentos e retorno financeiro.</p>
                        <p>Referência no lançamento de empreendimentos de alto e médio padrão, o Grupo Silva Packer destaca-se não apenas pelas suas grandes realizações, mas também por todas as possibilidades e versatilidades que oferece. Além do luxo, da sofisticação e do conforto, o grupo firma o compromisso de ser inovador e seguro, que prima pelo desenvolvimento ambiental, social e corporativo. Com esses valores, o Grupo Silva Packer se consagra cada vez mais como uma marca sólida, de respeito e sinônimo de rentabilidade e originalidade, agregando os mesmos princípios para Balneário Camboriú, que é o foco de seus empreendimentos.</p>
                    </div>
                </div>
            </div>

            <div class="container container-empresa">
                <div class="empresa cols">
                    <div class="col-2-10 thumb">
                        <img src="/assets/images/grupo-fjc.png" width="169" height="83" />
                    </div>
                    <div class="col-8-10">
                        <p>A FJC Construtora é uma empresa atuante no segmento de incorporações, construção civil e mercado imobiliário em Santa Catarina. Está estabelecida na cidade de Balneário Camboriú e é uma afiliada do Grupo Silva Packer.
                            Desde 2001, o grupo vem pesquisando o mercado brasileiro e descobriu no Sul do Brasil o maior potencial de investimento imobiliário do país.
                            A empresa traz através da credibilidade os conceitos de satisfação, praticidade e conforto com o foco no cliente do mercado imobiliário.</p>
                        <p>A FJC Construtora e Incorporadora concentra seus esforços na busca do melhor resultado, aliando qualidade com inteligência, o melhor aproveitamento dos recursos e o conceito construtivo do desperdício zero, o que confere a certeza do melhor investimento em imóveis.</p>

                        <p>Rua 3150, n.º 409, Balneário Camboriú – SC<br>
                        47 3366-2330 | 47 99103-3513<br>
                        www.construtorafjc.com.br</p>
                    </div>
                </div>
            </div>

            <div class="container container-empresa">
                <div class="empresa cols">
                    <div class="col-2-10 thumb">
                        <img src="/assets/images/grupo-silvaadm.png" width="175" height="71" />
                    </div>
                    <div class="col-8-10">
                        <p>A SILVA Administradora de Bens é uma Administradora de Imóveis, criada em 2012, com o propósito de unir gestão imobiliária do Grupo Silva Packer com a tecnologia dos sistemas de gestão.</p>
                        <p>Nós da SILVA Administradora de Bens entendemos que o mundo moderno nos exige cada vez mais, e que a gestão patrimonial necessita de uma empresa de qualidade para entregar uma gestão eficaz, transparente e de confiança. Por isso investimos continuamente em uma equipe qualificada, soluções inovadoras e sistema de gestão completo, acesso aos relatórios mensais, balancetes, reservas de espaços e todas as atividades comuns a administração patrimonial, tudo isso via profissionais qualificados, permitido o melhor gerenciamento, além de prezar pelo bom atendimento pessoal e proximidade com seus clientes.</p>
                        <p>Nosso compromisso é entregar comodidade, segurança, bem-estar e principalmente transparência da administração aos clientes, a fim de alcançar a valorização e gestão completa do patrimônio.</p>
                    </div>
                </div>
            </div>

        </section>

        <s-newsletter />
        
        <s-rodape />
        
    </div>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: 'Institucional',
            meta: [
               { 
                   name: 'description', 
                   content: 'No mercado desde 2001 e formada Carlos Humberto Metzner Silva e João Paulo Packer Silva, a Silva Packer está entre as melhores empresas do ramo da construção civil do sul do país, sendo reconhecidos pela inovação e segurança de nossos projetos.' 
                },
                { 
                    name: 'keywords', 
                    content: 'construtora, construção civil, incorporadora, silva packer, silvia packer, silva paker, balneário camboriu, balneário camburiu, santa catarina, imóveis, apartamentos, alto padrão, imóveis alto padrão, litoral catarinense, sul, investimento, investimento em imóveis, imobiliária.' 
                }
            ]
        }
    },
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";


    section#quemsomos{

        .institucional-foto{
            width: 90%;
            height: auto;
        }

        h1{
            color: @cor-preto;
            margin-top: 0;
            margin-left: 25px;
        }

        p{
            line-height: 30px;
            margin: 25px 0;
        }

    }

    section#politica-visao{
        background-color: @cor-cinza-claro;
        padding: 70px 0;

        h3{
            color: @cor-vermelho;
            text-transform: uppercase;
            font-size: 14px;
        }

        p{
            font-size: 14px;
            line-height: 25px;
        }
    }

    section#grupo{
        background-image: url(/assets/images/grupo-bg.jpg);

         h1{
            margin-left: 25px;
            color: @cor-cinza-claro;
        }

        .empresa{
            color: @cor-branco;
            font-size: 14px;
            line-height: 24px;

            border-bottom: 1px solid @cor-cinza-claro;
            padding-bottom: 54px;
            margin-bottom: 54px;


            .thumb{
                background-color: @cor-cinza-claro;
                display: flex;
                align-items: center;
                justify-content: center;
                
                img{
                    
                }
                
            }

            p{
                padding-left: 35px;
                line-height: 30px;
                margin-bottom: 35px;
                display: block;
            }

        }

        .container-empresa{

            &:last-child{
                .empresa{
                    
                    border: 0;
                    padding-bottom: 0;

                }
            }
        }

    }

</style>