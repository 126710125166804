<template>
    <section id="newsletter">
        <div class="container">

            <div class="col-3-12 col-off-2-12">
                <p><strong>Assine nossa<br>
                Newsletter</strong></p>

                <p>Receba as nossas novidades 
                sempre em primeira mão.</p>
            </div>

            <div class="col-4-12  col-off-1-12">
               <form action="#" method="POST" class="rodape" @submit="cadastrar">
                   <input type="text" placeholder="Seu Nome" v-model="nome" :disabled="ocupado" required />
                   <input type="email" placeholder="Seu Melhor E-mail" v-model="email" :disabled="ocupado" required />
                   <button  :disabled="ocupado" >{{(ocupado)?'. . .':'Enviar'}}</button>
               </form>
            </div>

        </div>
    </section>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    
    data: function(){
        return {
            nome: null,
            email: null,
            ocupado: false
        }
    },

    methods:{

        cadastrar: function(e){

            e.preventDefault();

            var $this = this;


            $this.ocupado = true;

            this.$sdk.post('newsletter', {nome: this.nome, email: this.email}, function(result){


                if(result.status == 'ok'){

                    Swal.fire(
                        result.titulo,
                        result.msg,
                        'success'
                    );

                    $this.nome = null;
                    $this.email = null;

                } else {

                    Swal.fire(
                        result.titulo,
                        result.msg,
                        'error'
                    );

                }

                $this.ocupado = false;

            })

        }

    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";
    
    section#newsletter{

        padding-top: 100px;
        padding-bottom: 100px;

    }

</style>