<template>
    
    <div>
        <s-cabecalho />

        <section id="blog">
            <div class="container pad-t60 pad-b60">
                
                <div class="cols">

                    <div class="col-2-3">
                        
                        <h1 v-if="titulo != null">{{titulo}}</h1>
                         

                        <div class="artigos">

                            <div class="artigo" v-for="(post, index) in posts" :key="index" @click="abre_post(post)">
                                <div class="conteudo">
                                    <div class="thumb" :style="'background-image: url('+ $sdk.prefix_images + post.ImagemCapa +');'"></div>
                                    <h1>{{post.Titulo}}</h1>
                                    <p>
                                        {{ post.Texto}}
                                    </p>
                                </div>
                            </div>  
                            
                        </div>

                        <div id="semartigos" v-if="posts.length == 0">
                            Nenhum post encontrado.
                        </div>
                        
                    </div>

                    <div class="col-1-3">
                        <c-sidebar />
                    </div>

                </div>
                
            </div>
        </section>

        <s-rodape />
    </div>


</template>

<script>
export default {

    metaInfo() {
        return {
            title: 'Blog',
        }
    },

    data: function(){
        return {
            posts: [],
            titulo: null
        }
    },

    beforeRouteUpdate(to, from, next) {
        next();
        this.load_lista();
    },

    beforeRouteLeave (to, from, next) {

        next();
        this.load_lista();
    },

    mounted: function(){

        var $this = this;

        setTimeout(function(){
            $this.load_lista();
        }, 500)
        
    },

    methods: {

        load_lista: function(){

            this.topo();

            this.titulo = null;

            var $this = this;

            var uri = 'posts';

            if($this.$route.name == 'blog-tags'){

                uri += '?tag=' + $this.$route.params.tag;
                this.titulo = 'Posts com a tag: ' + $this.$route.params.tag;

            } else if($this.$route.name == 'blog-categoria'){

                this.titulo = 'Posts com a categoria: ' + $this.$route.params.nome;
                uri += '?categoria=' + $this.$route.params.id;

            } else if($this.$route.name == 'blog-datas'){

                var mes = null;
                switch(parseInt($this.$route.params.mes)){
                    case 1: mes = 'Janeiro'; break;
                    case 2: mes = 'Fevereiro'; break;
                    case 3: mes = 'Março'; break;
                    case 4: mes = 'Abril'; break;
                    case 5: mes = 'Maio'; break;
                    case 6: mes = 'Junho'; break;
                    case 7: mes = 'Julho'; break;
                    case 8: mes = 'Agosto'; break;
                    case 9: mes = 'Setembro'; break;
                    case 10: mes = 'Outubro'; break;
                    case 11: mes = 'Novembro'; break;
                    case 12: mes = 'Dezembro'; break;
                    default: mes = $this.$route.params.mes; break;
                        
                }

                this.titulo = 'Posts de ' + mes + ' de ' + $this.$route.params.ano;
                uri += '?data=' + $this.$route.params.ano + '-' + $this.$route.params.mes;

            } else if($this.$route.name == 'blog-pesquisa'){
                this.titulo = 'Pesquisando por "' + $this.$route.params.pesquisa + '"';
                uri += '?pesquisa=' + $this.$route.params.pesquisa;
            }

            //zera lista
            $this.posts = [];

            this.$sdk.get(uri, function(data){

                console.log('API - Carregando posts...');

                data.forEach( function(post) {

                    post.Texto = post.Texto.replace(/(<([^>]+)>)/gi, ""); //retira tags do texto
                    $this.posts.push(post);

                });

            })


        },

        abre_post: function(post){
            this.$router.push('/blog/' + post.LinkAmigavel);
        },

        topo: function(){
            
            var element = document.querySelector('div#miolo');
            var top = element.offsetTop;

            window.scrollTo({
                top: top,
                left: 0,
                behavior: 'smooth'
            });
            
        },

    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    section#blog{

        background-color: @cor-cinza-escuro;

        h1{
            color: @cor-branco!important;
        }

        .artigos{

            display: flex;
            flex-wrap: wrap;

            .artigo{

                flex-basis: 50%;
                max-width: 50%;
                flex: 50%;
                position: relative;
                cursor: pointer;

                &:hover, &:focus{
                    .conteudo{
                        h1{
                            text-decoration: underline;
                        }
                    }
                }

                .conteudo{

                    padding: 25px;
                    
                    .thumb{
                        width: 100%;
                        background-size: cover;
                        background-repeat: repeat;
                        background-position: center;

                        &:after{
                            content: '';
                            display: block;
                            padding-bottom: 100%;
                        }

                    }

                    h1{
                        color: @cor-cinza-claro!important;
                        font-size: 25px;
                        font-weight: 800;
                        margin: 30px 0;
                        padding: 0;
                        line-height: 30px;
                        letter-spacing: normal;

                        
                        &:before{
                            display: none;
                        }
                    }

                    p{
                        color: @cor-cinza-claro;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space:normal;
                        max-height: 200px;
                        
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                    }

                    &:after{
                        content: "Saiba Mais";
                        background-color: @cor-vermelho;
                        color: @cor-branco;
                        padding: 4px 6px;
                        text-transform: uppercase;
                        font-size: 12px;
                    }

                }

                &:nth-child(1),
                &:nth-child(2){
                    .conteudo{
                        padding-top: 0;
                    }
                }

            }

        }

        #semartigos{
            color: @cor-branco;
        }


    }

</style>