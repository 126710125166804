<template>
    <div :class="($route.name == 'home-branca'?'branco':null)">
        <s-cabecalho />
        
        <section id="vinteanos">
            <div class="container">
                <img src="/assets/images/20anos.png" alt="">
            </div>
        </section>

        <section id="emp-reg" class="pad-t85 pad-b85">
            
            <div class="container">
                <h1>Empreendimentos</h1>
            </div>

            <div class="container">

                <c-empreendimentos :empreendimentos="empreendimentos" />

            </div>

             <div class="container" id="numeros" v-if="false">

                <div class="cols">

                    <div class="col-1-4">
                        
                        <div class="num">
                            <span class="grande">15.000m²</span>
                            <br>
                            <span class="pequeno">em OBRAS ENTREGUES</span>
                        </div>

                    </div>

                    <div class="col-1-4">
                        
                        <div class="num">
                            <span class="grande">XX.000m²</span>
                            <br>
                            <span class="pequeno">em OBRAS EM CONSTRUÇÃO</span>
                        </div>

                    </div>


                    <div class="col-1-4">
                         <div class="num">
                            <span class="grande">15</span>
                            <span class="pequeno dolado">EMPREENDIMENTOS <br>JÁ ENTREGUES</span>
                        </div>

                    </div>
                    <div class="col-1-4">
                         <div class="num">
                            <span class="grande">05</span>
                            <span class="pequeno dolado">OBRAS EM<br> CONTRUÇÂO</span>
                        </div>

                    </div>

                </div>

            </div>

            <div class="container pad-b40 pad-t40">
                <img src="/assets/images/bc-e-regiao-titulo.png" v-if="$route.name != 'home-branca'">
                <img src="/assets/images/bc-e-regiao-titulo-branco.png" v-if="$route.name == 'home-branca'">
            </div>




            <CoolLightBox 
                :items="galeriabc" 
                :index="fotobc"
                @close="fotobc = null">
            </CoolLightBox>
            <div class="container" id="be-e-regiao" v-if="galeriabc.length > 0">
                <div class="container">
                    

                    <div class="cols">
                        <div class="col-1-3">
                            <div class="foto" :style="'background-image:url('+galeriabc[0].src+');'"></div>
                            <div class="foto" :style="'background-image:url('+galeriabc[1].src+');'"></div>
                        </div>
                        <div class="col-2-3">
                            <div class="foto" :style="'background-image:url('+galeriabc[2].src+');'">
                                <h1 @click="fotobc = 0"></h1>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

             <div class="container ultimas">
                <h1>Últimas do Blog</h1>
            </div>

            <div class="container ultimas" v-if="posts_parte(1)!= []">

                <div class="cols">
                    <div class="col-1-3 post" v-for="(post, index) in posts_parte(1)" :key="index" @click="abre_post(post)">
                        <div class="imagem" :style="'background-image:url('+ $sdk.prefix_images + post.ImagemCapa+');'">
                            <div class="titulo">{{post.Titulo}}</div>
                            <div class="tituloBG"></div>
                            <div class="cta-bg"></div>
                            <div class="cta">Clique e Saiba mais</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container ultimas" v-if="posts_parte(2).length > 0">
                
                <div class="cols">
                    <div class="col-1-3" v-for="(post, index) in posts_parte(2)" :key="index">
                        {{ post.Titulo }}    
                    </div>
                </div>

            </div>

        </section>


        <s-newsletter />

        <s-rodape />
        
    </div>
</template>

<script>

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {

    metaInfo() {
        return {
            title: 'Home',
            meta: [
               { 
                   name: 'description', 
                   content: 'Há 20 anos no mercado, a Silva Packer é referência em construções de alto padrão e satisfação imobiliária.' 
                },
                { 
                    name: 'keywords', 
                    content: 'imóveis, alto padrão, Balneário Camboriú, Balneario Camburiu, apartamentos, apartamentos de luxo, apartamento alto padrão, investimentos, imobiliárias, silva packer, silvia packer, silva paker, praia, litoral, Santa Catarina, litoral catarinense, Carlos Humberto, construtora.' 
                }
            ]
        }
    },


    components: {
        CoolLightBox,
    },

    data: function () {
        return {
            empreendimentos: [],

            banner_atual: null,
            banner_keys: [],
            banner_timeout: null,

            posts: [
                
            ],

            galeriabc: [],
            fotobc: null
        }
    },

    methods: {

        load_empreendimentos: function(){

            var $this = this;

            //$this.empreentimentos = [];
            this.$sdk.get('empreendimentos?naoconcluidos', function(data){
                $this.empreendimentos = data;
            })

        },

        load_posts: function(){

            var $this = this;

            this.$sdk.get('posts?qtd=6', function(data){
                $this.posts = data;
            })

        },

        load_galeriabc: function(){

            var $this = this;


            $this.galeriabc = [];
            this.$sdk.get('galeriabc', function(data){

                data.forEach(function(foto) {

                    $this.galeriabc.push({
                        src : $this.$sdk.prefix_images + foto.Imagem,
                        title: foto.Titulo
                    })
                    
                });

                //embaralha ordem
                $this.galeriabc.sort(() => Math.random() - 0.5)              
                

            })

        },

         posts_parte: function(parte){

            if(parte == 1)
                return this.posts.slice(0,3);
            else
                return this.posts.slice(3,6);

        },

        topo: function(){
            
            var element = document.querySelector('div#miolo');
            var top = element.offsetTop;

            window.scrollTo({
                top: top,
                left: 0,
                behavior: 'smooth'
            });
            
        },

        abre_post: function(post){

            var $this = this;

            this.topo();

            setTimeout(function(){

                $this.$router.push({ name: 'artigo', params: { slug: post.LinkAmigavel }});    

            }, 400)
        },

    },

    mounted: function(){
        this.load_posts();
        this.load_empreendimentos();
        this.load_galeriabc();
    },

    computed: {
    
    },

     

}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";

    section#vinteanos{
        padding: 150px 0;
        background-image: url(/assets/images/20anos-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        img{
            display: flex;
            margin: auto;
        }
    }

    section#emp-reg{
        background-image: url(/assets/images/emp-reg-detalhe.png), url(/assets/images/emp-reg-bg.jpg);
        background-repeat: no-repeat, no-repeat;
        background-size: auto, cover;
        background-position: center 245px, top center;
        //padding-bottom: 720px;
        //margin-bottom: 100px;

        h1{
            color: @cor-cinza-claro;
        }


        #numeros{
            color: @cor-branco;
            padding: 135px 0;

            .num{

                padding-left: 50px;

                .grande{
                    font-size: 30px;
                    font-weight: 600;
                    letter-spacing: 3px;
                }

                .pequeno{
                    font-weight: 600;
                    font-size: 14px;

                    &.dolado{
                        position: absolute;
                        margin-left: 10px;
                    }
                }

                &:before{
                    content: "";
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background-color: @cor-vermelho;
                    margin-left: -25px;
                    margin-top: 17px;
                }
            }
        }

        #be-e-regiao{

            .foto{

                width: 100%;

                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                position: relative;

                transition: 0.3s;

                &:after{
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                &:hover{
                    background-size: 110%;
                }
            }

            .cols{


                 h1{
                    position: absolute;
                    text-align: right;
                    right: 100px;
                    color: @cor-branco;
                    
                    padding-right: 20px;
                    bottom: 80px;
                    &:before{
                        display: none;
                        /*
                        position: absolute;
                        right: 0;
                        margin-left: 0;
                        */
                    }

                    &:after{
                        content: "SAIBA MAIS";
                        position: absolute;
                        top: 60px;
                        left: 0;

                        position: absolute;
                        display: block;
                        z-index: 999;
                        background-color: #ffffff;
                        text-transform: uppercase;
                        padding: 1px 0;
                        font-size: 14px;
                        letter-spacing: 1px;
                        width: 130px;
                        text-align: center;
                        color: #37312a;
                        text-decoration: none;
                        opacity: 0.6;
                        transition: all 0.5s;
                        line-height: 25px;
                        left: 50%;
                        margin-left: -70px;
                        z-index: 10;
                        cursor: pointer;

                        &:hover, &:focus{
                            opacity: 1;
                        }
                    }
                }


            }


        }

        .ultimas{

            h1{
                color: @cor-branco;
            }

            .post{

                cursor: pointer;
            
                .imagem{
                    width: 100%;
                    background-color: @cor-cinza-claro;
                    background-size: auto 100%;
                    background-position: center;

                    transition: 0.3s;
                    position: relative;
                    overflow: hidden;

                    &:before{
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    .cta-bg{
                        position: absolute;
                        height: 50px;
                        background-color: @cor-vermelho;
                        bottom: -50px;
                        width: 100%;

                    }

                    .cta{
                        position: absolute;
                        line-height: 50px;
                        bottom: -50px;
                        width: 100%;
                        text-align: center;
                        font-weight: 600;
                        transition: 0.40s;
                        font-size: 14px;
                        color: @cor-branco;

                    }

                    .titulo{
                        color: @cor-preto;
                        text-align: center;
                        font-weight: 700;
                        font-size: 13px;
                        text-transform: uppercase;
                        bottom: 15px;
                        position: absolute;
                        width: 80%;
                        margin: 0 10%;
                        //text-shadow: 2px 2px 2px rgb(0 0 0 / 58%);
                        line-height: 15px;
                        height: 30px;
                        z-index: 99;
                        position: absolute;

                    }
                    .tituloBG{
                        position: absolute;
                        width: 100%;
                        background-color: @cor-branco;
                        height: 60px;
                        bottom: 0;
                        z-index: 90;
                        opacity: 0.6;
                        transition: 0.3s;
                    }
                }

                &:hover, &:focus{
                    .imagem{
                        background-size: auto 110%;

                        .tituloBG{
                            opacity: 0.8;
                        }

                    }

                    
                }
            }
        }
    }

    .branco{
        section#emp-reg{
            background-image: url(/assets/images/emp-reg-detalhe.png), url(/assets/images/emp-reg-bg-branco.jpg);
        }

        h1{
            color: @cor-preto!important;
        }

        #numeros{
            color: @cor-preto!important;
        }
    }

</style>