<template>
    <div>
        <s-cabecalho />
        
        <section id="contato">
            <div id="miolo"></div>
         
            <div class="container">

                <div class="col-3-8 col-off-1-8">
                    <h1>Entre em Contato</h1>
                </div>
            </div>

            <div class="container">

                <form action="#" class="rodape">

                    <div class="cols">

                        <form @submit="cadastrar">

                            <div class="col-3-8 col-off-1-8">

                                <div class="coluna">
                                    <p><strong>Podemos ajudar você a escolher<br>
                                    o apartamento dos seus sonhos!</strong></p>

                                    <input type="text" placeholder="Nome" required v-model="nome" :disabled="ocupado">
                                    <input type="phone" placeholder="Telefone" required v-model="telefone" :disabled="ocupado">
                                    <input type="email" placeholder="E-mail" required v-model="email" :disabled="ocupado">
                                    <input type="assunto" placeholder="Assunto" required v-model="assunto" :disabled="ocupado">
                                </div>
                                
                            </div>
                            <div class="col-3-8">
                                <div class="coluna">
                                    <p>Envie sua mensagem, agende uma ligação<br>
                                    ou passe aqui para tomarmos um café.</p>

                                    <textarea placeholder="Mensagem" required v-model="msg" :disabled="ocupado"></textarea>
                                    <button :disabled="ocupado">{{(ocupado)?'. . .':'Enviar'}}</button>
                                </div>
                                
                            </div>

                        </form>
                    </div>

                </form>

            </div>
         
        </section>
        
        <s-rodape />
        
    </div>
</template>


<script>
import Swal from 'sweetalert2'

export default {

    metaInfo() {
        return {
            title: 'Contato',
            meta: [
                { 
                    name: 'description', 
                    content: 'Temos o apartamento dos seus sonhos. Entre em contato conosco e agende um horário.' 
                },
                { 
                    name: 'keywords', 
                    content: 'Contato, empreendimentos, Balneário Camboriú, Balneario Camburiu, silva packer, silvia packer, silva paker, santa catarina, imóveis, apartamentos, alto padrão, imóveis alto padrão, litoral catarinense, sul, investimento, investimento em imóveis, imobiliária, solar di siena, solar di capri, 135 jardins, tesla' 
                }
            ]

        }
    },
    
    data: function(){
        return {
            nome: null,
            telefone: null,
            email: null,
            assunto: null,
            msg: null,
            ocupado: false
        }
    },

    methods:{

        cadastrar: function(e){

            e.preventDefault();

            var $this = this;


            $this.ocupado = true;

            this.$sdk.post('contato', {
                nome:       this.nome,
                telefone:   this.telefone,
                email:      this.email,
                assunto:    this.assunto,
                msg:        this.msg,
            }, function(result){


                if(result.status == 'ok'){

                    Swal.fire(
                        result.titulo,
                        result.msg,
                        'success'
                    );

                    $this.nome = null;
                    $this.email = null;
                    $this.telefone = null;
                    $this.assunto = null;
                    $this.msg = null;

                } else {

                    Swal.fire(
                        result.titulo,
                        result.msg,
                        'error'
                    );

                }

                $this.ocupado = false;

            })

        }

    }
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";


    section#contato{
        padding: 100px 0;

        h1{
            color: @cor-vermelho;
            letter-spacing: 0;
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 15px;

            &:after,
            &:before{
                display: none;
            }
        }

        form{
            display: flex;
            width: 100%;

            textarea{
                min-height: 120px;
            }
        }

        .coluna{
            padding: 15px;
        }
    }
</style>