import Vue                  from 'vue'

import c_banners            from './components/banners.vue';
import c_sidebar            from './components/blog-sidebar.vue';
import c_galeria            from './components/galeria.vue';
import c_empreendimentos    from './components/empreendimentos.vue';

Vue.component('c-banners',          c_banners);
Vue.component('c-sidebar',          c_sidebar);
Vue.component('c-galeria',          c_galeria);
Vue.component('c-empreendimentos',  c_empreendimentos);
