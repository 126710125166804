export default {

   


    /**
     * Instalar use no Vue.
     * 
     * @param {Vue} Instancia do Vue
     */
    install(Vue) {
        Vue.prototype.$helpers = this;
        Vue.helpers = this;
    }
};